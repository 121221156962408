<script>
	import GlobalVue from '../../helper/Global.vue'
	export default {
		extends: GlobalVue,
		data() {
			return {
				input: {},
			}
		},
		computed: {},
		mounted() {
			this.$emit("ready", this)
		},
		methods: {
			
		},
		watch: {}
	};
</script>

<template>

</template>